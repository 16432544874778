@import 'vars';


body {
    font-family: $font-primary;
    font-size: 16px;
    color: $black;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size:40px;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: #FFF;
    background-color: grey;
    border: 1px solid grey;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover {
    color: grey;
    background-color: transparent;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}
.text-black{
    color:$black;
}
.text-brown{
    color:$brown;
}
.text-white{
    color:white;
}
.font-big{
    font-size: clamp(24px, 5vw, 47px);
}

.font-extra-light{
    font-weight: 200;
}
.font-primary{
    font-family: $font-primary;
}
.font-secondary{
    font-family: $font-secondary;
}
blockquote{
    font-family: $font-primary;
    font-weight: 700;
    font-size: 20px;
    color:$brown;
    border-top:1px solid $brown;
    border-bottom:1px solid $brown;
    margin-left: -74px;
    margin-right: -74px;
    padding: 1rem 5px;
    @media screen and (max-width:1300px) {
        margin:0;
    }

}
.blockquote-big{
    font-size: clamp(20px, 4vw, 35px);
}
.padded{
    padding-left:2rem;
    padding-right:2rem;
}
img.fullwidth{
    aspect-ratio: 16/9;
    width: 100%!important;
    height: auto;
    object-fit: cover;
}

.two-col{
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    @media screen and (max-width: 768px) {
        grid-template-columns:  1fr;
    }

}
.three-col{
    
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    @media screen and (max-width: 960px) {
        grid-template-columns:  repeat(2, 1fr);
    }
    @media screen and (max-width: 576px) {
        grid-template-columns:  1fr;
        text-align: center;
    }
}
ul{
    &.two-col, &.three-col{
        margin:0;
        padding:0;
        list-style: none;
        img{
            width: 100%!important;
            aspect-ratio: 1/1;
            object-fit: cover;
        }
    }
}
.image-hover{
    transition: filter .3s ease-in-out;
    &:hover{
        filter: brightness(0) invert(1);
    }
}