@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300;1,400;1,500&family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

// Font Variables
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Barlow Semi Condensed', sans-serif;

$black:#383D47; 
$brown: #AB7C50;
$light-brown: #DBB38D;
$grey:#707070;
$dark-grey:#4D525E